<template>
  <div class="errors-table-component">
    <v-data-table
      :headers="headers"
      :items="tableData"
      :hide-default-footer="windowWidth <= 960 ? true : false"
      :disable-pagination="windowWidth <= 960 ? true : false"
      mobile-breakpoint="960"
    >
      <!-- eslint-disable -->
      <template v-slot:header.createdAt="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.category="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.machine="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.status="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.errorStatus="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>
      <template v-slot:header.finishedAt="{ header }">
        <span class="header-text">{{ header.text }}</span>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <div class="created-at">
          {{ dateHelper(item.createdAt) }} {{ timeHelper(item.createdAt) }}
        </div>
      </template>
      <template v-slot:item.machine="{ item }">
        <div class="machine">
          {{ item.machine }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <div
          v-if="item.status === $t('error-report.machines-report.stopped')"
          class="status"
        >
          <img
            :src="CardIcon1"
            :alt="$t('error-report.machines-report.stopped')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === $t('error-report.machines-report.limited')"
          class="status"
        >
          <img
            :src="CardIcon2"
            :alt="$t('error-report.machines-report.limited')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === $t('error-report.machines-report.poorQuality')"
          class="status"
        >
          <img
            :src="CardIcon3"
            :alt="$t('error-report.machines-report.poorQuality')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === $t('error-report.machines-report.plannedMaintenance')"
          class="status"
        >
          <img
            :src="CardIcon4"
            :alt="$t('error-report.machines-report.plannedMaintenance')"
            class="status-icon"
          >
        </div>
        <div
          v-if="item.status === $t('error-report.machines-report.running')"
          class="status"
        >
          <img
            :src="CardIcon5"
            :alt="$t('error-report.machines-report.running')"
            class="status-icon"
          >
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="name">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.errorStatus="{ item }">
        <div class="error-status">
          <div
            v-if="item.errorStatus === $t('dashboard.error-table.table.unassigned')"
            class="nobody"
          >
            {{ item.errorStatus }}
          </div>
          <div
            v-else
            class="assigned"
          >
            <img
              :src="AssignedIcon"
              alt="Icon"
              class="icon"
            >
            {{ item.errorStatus }}
          </div>
        </div>
      </template>
      <template v-slot:item.finishedAt="{ item }">
        <div class="finished-at">
          {{ dateHelper(item.finishedAt) }} {{ timeHelper(item.finishedAt) }}
        </div>
      </template>
      <template v-slot:item.more="{ item }">
        <div
          class="more"
          @click="showMore = item.id"
        >
          <img
            v-if="showMore === item.id"
            :src="MoreOnIcon"
            alt="MoreOnIcon"
            class="more-icon"
          >
          <img
            v-else
            :src="MoreOffIcon"
            alt="MoreOffIcon"
            class="more-icon"
          >
          <div
            v-if="showMore === item.id"
            v-click-outside="closeMore"
            class="more-container"
          >
            <ul class="items">
              <li
                class="item"
                @click="$router.push({ name: 'ErrorDetailMachine', params: { id: item.id }})"
              >
                <img
                  :src="ShowDetailIcon"
                  alt="Icon"
                  class="icon"
                >
                {{ $t('dashboard.error-table.table.showMore.detail') }}
              </li>
            </ul>
          </div>
        </div>
      </template>
      <!-- eslint-enable -->
    </v-data-table>
  </div>
</template>

<script>
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'
import AssignedIcon from '@/assets/icons/assigned_icon.svg'
import MoreOffIcon from '@/assets/icons/more_off.svg'
import MoreOnIcon from '@/assets/icons/more_on.svg'
import AddUserIcon from '@/assets/icons/add_user_error.svg'
import ShowDetailIcon from '@/assets/icons/show_detail_error.svg'
import { timeHelper, dateHelper } from '@/helpers/dateHelpers'

export default {
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5,
      AssignedIcon,
      MoreOffIcon,
      MoreOnIcon,
      AddUserIcon,
      ShowDetailIcon,
      showMore: null,
      headers: [
        { text: this.$t('dashboard.error-table.table.headers.dateTime'), value: 'createdAt' },
        { text: this.$t('dashboard.error-table.table.headers.machine'), value: 'machine' },
        { text: this.$t('dashboard.error-table.table.headers.status'), value: 'status' },
        { text: this.$t('dashboard.error-table.table.headers.type'), value: 'name' },
        { text: this.$t('dashboard.error-table.table.headers.jobStatus'), value: 'errorStatus' },
        { text: this.$t('dashboard.error-table.table.headers.finishedDateTime'), value: 'finishedAt' },
        { text: '', value: 'more', sortable: false }
      ],
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    dateHelper,
    timeHelper,
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    closeMore () {
      this.showMore = null
    }
  }
}
</script>

<style lang="scss" scoped>
// Table header
.header-text {
  font-weight: 500;
  color: $primary-grey;
  letter-spacing: 0.4px;
}

// Table body
div.created-at,
div.finished-at,
div.category,
div.machine,
div.status,
div.name,
div.error-status {
  display: flex;
  align-items: center;
  font-size: 14px;
  height: 56px;
  color: $primary-black;

  img.status-icon {
    width: 24px;
    height: 24px;
  }

  div.nobody {
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    height: 24px;
    padding: 2px 24px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: $primary-black;
  }

  div.assigned {
    display: flex;
    align-items: center;
    background-color: $status-green;
    // height: 24px;
    padding: 2px 12px;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: $primary-white;

    img.icon {
      margin-right: 6px;
      width: 12px;
      height: 12px;
    }
  }
}

div.more {
  position: relative;
  cursor: pointer;

  img.more-icon {
    width: 24px;
    height: 24px;
  }

  div.more-container {
    position: absolute;
    right: 32px;
    top: -71px;
    width: 176px;
    padding: 8px 0;
    background-color: $primary-white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.05);

    ul.items {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      width: 100%;

      li.item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 12px 25px;

        &.dark {
          background-color: #F8F8F8;
        }

        img.icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
  }
}

div.errors-table-component {
  width: 100%;
  max-width: 1108px;
  background-color: $primary-white;
  padding: 34px 32px;

  div.table-first-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    h6 {
      font-weight: 500;
    }

    .show-more {
      text-decoration: none;
      color: $primary-lightorange;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: $mobile) {
  div.errors-table-component {
    padding: 31px 24px;

    div.table-first-row {
      align-items: center;
      margin-bottom: 23px;

      h6 {
        font-size: 18px;
      }
    }
  }

  div.more {
    div.more-container {
      width: 180px;

      ul.items {
        li.item {
          padding: 12px 19px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
