<template>
  <div class="errors-history-wrapper">
    <div class="page-header">
      <div class="page-header__content">
        <div class="left">
          <img
            v-if="windowWidth > 960"
            :src="ButtonIcon"
            alt="Back"
            class="back-button"
            @click="$router.push({ name: 'Dashboard' })"
          >
          <h4>{{ $t('machine-errors-history.heading') }}</h4>
        </div>
        <div class="right">
          <custom-button
            :fullwidth="windowWidth <= 960 ? true : false"
            @click="$router.push({ name: 'ErrorReport' })"
          >
            <template v-slot:leftIcon>
              <img
                :src="CustomButtonIcon"
                alt="Plus icon"
              >
            </template>
            {{ $t('machine-errors-history.reportErrorButton') }}
          </custom-button>
        </div>
      </div>
    </div>
    <machine-errors-history-table />
  </div>
</template>

<script>
import ButtonIcon from '@/assets/arrow_button.svg'
import CustomButton from '@/components/GlobalComponents/CustomButton.vue'
import CustomButtonIcon from '@/assets/icons/add.svg'
import MachineErrorsHistoryTable from './MachineErrorsHistoryTable'

export default {
  name: 'MachineErrorsHistory',
  components: {
    CustomButton,
    MachineErrorsHistoryTable
  },
  data () {
    return {
      ButtonIcon,
      CustomButtonIcon,
      windowWidth: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
div.errors-history-wrapper {
  width: 100%;
  padding-top: 72px; // because of header

  div.page-header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 225px;
    background-color: $primary-darkgrey;
    padding: 48px 166px;

    div.page-header__content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1108px;
      height: 64px;

      div.left {
        display: flex;
        align-items: center;

        img.back-button {
          cursor: pointer;
          margin-right: 32px;
        }

        h4 {
          color: $primary-white;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.errors-history-wrapper {
    padding: 64px 0 100px 0;

    div.page-header {
      height: 149px;
      padding: 24px;

      div.page-header__content {
        flex-direction: column;
        max-width: 100%;
        justify-content: unset;
        height: unset;

        div.left {
          h4 {
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 0.5px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
</style>
